html {
  background-color: #0e0e0e;
}

.header {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/main_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.nav {
  padding: 24px 60px;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.nav .logo {
  height: 48px;
}

.App-logo-wrap {
  padding: 87px 0 19px 0;
  width: 100%;
}

.slogan-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 115px;
}

.slogan {
  text-align: center;
}

.Title-primary {
  color: rgb(255, 255, 255) !important;
  margin-bottom: 8px !important;
}

.Title-secondary {
  color: rgb(255, 255, 255) !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

.header-form-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.App-logo {
  height: 110px;
  pointer-events: none;
}

.App-logo-text {
  height: 60px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.sub-content-wrap {
  padding-top: 48px;
}

.sub-content,
.sub-content-form {
  padding: 24px 0;
  display: flex;
  align-items: center;
  column-gap: 48px;
}

.sub-content-form {
  padding-top: 120px;
  padding-bottom: 72px;
  align-items: normal;
}

.sub-content .title {
  flex: 1;
}

.sub-content .img {
  border-radius: 8px;
}

.sub-content-form .form,
.sub-content-form .title {
  flex: 1;
}

.footer-slogan {
  padding: 72px 0;
  text-align: center;
}

.App-footer-logo {
  height: 240px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 24px 60px;
}

@keyframes App-logo-spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@media (min-width: 1600px) {
  .header-form-wrap,
  .sub-content,
  .sub-content-form,
  .footer-slogan {
    padding-left: 240px;
    padding-right: 240px;
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .header-form-wrap,
  .sub-content,
  .sub-content-form,
  .footer-slogan {
    padding-left: 180px;
    padding-right: 180px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-form-wrap,
  .sub-content,
  .sub-content-form,
  .footer-slogan {
    padding-left: 120px;
    padding-right: 120px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .header-form-wrap,
  .sub-content,
  .sub-content-form,
  .footer-slogan {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 768px) {
  .header-form-wrap,
  .sub-content,
  .sub-content-form,
  .footer-slogan {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sub-content,
  .sub-content-form {
    flex-direction: column;
    align-items: normal;
  }

  .nav {
    padding: 8px 12px;
  }

  .nav .logo {
    height: 32px;
  }

  .header-form-wrap {
    flex-direction: column;
    padding-bottom: 0;
  }

  .header-form-wrap .main-intro-wrap {
    width: 100%;
  }

  .header-form-wrap .early-access-form-wrap {
    width: 100%;
  }

  .slogan {
    padding: 0px 12px;
    text-align: left;
  }

  .slogan-wrap {
    padding-bottom: 15px;
  }

  .App-logo-wrap {
    padding: 55px 0 15px 0;
  }

  .App-logo {
    height: 111px;
  }

  .App-logo-text {
    height: 45px;
  }

  .App-footer-logo {
    height: 180px;
  }

  .sub-content-wrap {
    padding-top: 0;
  }

  .footer {
    padding: 8px 12px;
  }
}

@media (min-width: 768px) {
  .header-form-wrap {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-form-wrap .main-intro-wrap {
    width: 45%;
    max-width: 664px;
  }

  .header-form-wrap .early-access-form-wrap {
    width: 45%;
    max-width: 456px;
  }

  .sub-content:nth-child(even) {
    flex-direction: row-reverse;
  }

  .sub-content .img {
    width: 360px;
    height: 360px;
  }
}
